//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import closeBtn from "@/components/shared/close-btn";
export default {
  name: "initial-country-selector",
  components:{ closeBtn},
  props:{
    countries:{
      type: Array,
      required: true
    }
  },
  methods:{
    onClose(){
      this.$emit("close");
    },
    changeCountry(country){
      localStorage.setItem("selectedCountry", JSON.stringify(country));
      localStorage.setItem("userCountry", JSON.stringify(window.geotargetly_country_code()));
      
      if (window.location.origin !== country.description){
        window.location.href = country.description + "?redirected-from=" + country.code + "&initial-redirect=true";
      }
      else {
        this.onClose();
      }
    }
  }
  
}
