//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CloseBtn from "@/components/shared/close-btn";
export default {
  name: "country-selector-dialog",
  components:{
    CloseBtn
  },
  props:{
    countries:{
      required: true,
      type: Array
    },
    userCountry:{
      required: true,
      type: Object
    }
    
  },
  data() {
    return{
      
    }
  },
  mounted() {

  },
  methods:{
    onClose(){
      localStorage.setItem("userCountry", JSON.stringify(window.geotargetly_country_code()));

      this.$emit('close');
    },
    changeCountry(){
      localStorage.setItem("selectedCountry", JSON.stringify(this.userCountry));
    
      if (window.location.origin !== this.userCountry.description){
        window.location.href = this.userCountry.description + "?redirected-from=" + this.userCountry.code;
      }
      else {
        this.onClose();
      }
    }
  }
}
