//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Menu',
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      category: '',
    }
  },
  created() {
    if(this.$route.path.includes('product-detail')) {
      const sku = this.$route.params.pathMatch.split('/').pop()
      this.$axios.get(
        '/ProductCategories/categorycodebyproductsku/' +
        sku
      ).then( response => {
        if (response.status === 200) {
          if (response.data.hasErrors === true) {
            const errors = response.data.errorMessages
            for (var key in errors){
              if (errors.hasOwnProperty(key)){   
                this.$toast.error(errors[key])
              }
            }
          } else {
            this.category = response.data.value.categoryCode
          }
        } else {
          this.$toast.error(
            'There was an error registering the user! Error code: ' +
              response.status
          )
        }
      })
    }
  },
  methods: {
    showItem(menuItemLink) {
      switch(menuItemLink){
        case 'advertising-materials':
          return false
        default:
          return true
      }
    },
    activeLink(navItem) {
      return this.localePath(`/${navItem.menuLink.fieldValue.value}`) === this.$route.path
    },
    activeProductLink(navItem){
      if(this.$route.path.includes('product-detail')) {
        return navItem.menuLink.fieldValue.value === (this.category ? `collections/${this.category.toLowerCase()}` : '')
      }
      return false
    },
  }
}
